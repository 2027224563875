//创建axios实例
import axios from 'axios'
import { Notification,Message } from 'element-ui'
// import { } from 'element-plus'

//创建axios实例
const service = axios.create({
	//超时时间，这里是5秒
	timeout: 5000,
})

// 添加请求拦截器
service.interceptors.request.use(res=> {  
	//配置请求头
	res.headers = {
		// 'Content-Type':'application/json',
		'Content-Type':'application/x-www-form-urlencoded'
	}	
    return res;
  }, error =>{
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(response=> {
	//只返回data中的信息
    const resp=response.data;
	//文件下载类型，直接返回所有的
	if (response.request.responseType == "blob") {
	    return response;
	}
    return resp;
  }, error => {
    let code=0
    try{
        code = error.response.status
        const message= error.response.data.message
        debugger
    }catch(e){
        if (error.toString().indexOf('Error: timeout') !== -1) {
            Message({
                message: '请求超时.....',
                type: 'error'
              })
            return Promise.reject(new error(message))
        }

    }
    if(code){
        debugger
        
        if(code==500){
            debugger
            const errorMsg = error.response.data.message
            if (errorMsg !== undefined) {
                Notification.error({
                    title: errorMsg,
                    duration: 0
                })
                return
            }
        }else if(code==404){
            debugger

            Message({
                message: '404',
                type: 'error'
            })
            return
        }else{
            Message({
                message: '请求失败',
                type: 'error'
              })
              return
        }
    }
    return Promise.reject(error);
});
export default service
