<template>
  <div class="home">
    <el-row >
  <el-col :span="12"><img src="../assets/images/header-logo.png" alt="" style="width:198px;height: auto;"></el-col>
  <el-col :span="12"><div class="grid-content bg-purple-light">
    <ul class="ul">
      <a href="#/home">
        <li>首页</li>
      </a>
      <a href="#/home?idName=produce">
        <li>产品</li>
      </a>
      <a href="#/home?idName=about">
        <li>关于我们</li>
      </a>
      <a href="#/apply"><el-button  icon="el-icon-edit">申请试用</el-button></a> 
<!-- <div></div> -->
    </ul>
  </div></el-col>
</el-row>
  </div>
  
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: 'Header-header',
  // components: {
  //   HelloWorld
  // },
  methods:{
  },
  created(){
    // console.log(this.$route.path,'path')
  },
}
</script>

<style lang="scss" scoped>
div{
  font-size: 16px;
}
a{
  text-decoration: none;
}
.el-row {
    // margin-bottom: 20px;
    background-color:#005DD0;
  }
  .ul{
    display: flex;
    justify-content: flex-start;
  }
  .ul li{
    width: 100px;
    list-style: none;
    line-height: 40px;
    color: aliceblue;
  }
  // .ul a:hover li{
  //   color:#005DD0;
  // }
.ul .el-button{
  margin-left:30px ;
  width: 200px;
  // background-image: url('../assets/images/index/Apply-bg.png');
}
img{
  margin-top: 5px;
}

</style>
