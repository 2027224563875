import requset from './request.js'

export function submitApply (data) {
    return requset({
      url:'/contract',
      method:'post',
      data: data
    })
  }

  export function submitemail (data) {
    return requset({
      url:'/price',
      method:'post',
      data: data
    })
  }