import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    // name: 'home',
    redirect:'/home',
  },
  {
    path: '/home',
    name: 'home',
    // redirect:'/home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '首页'
    },

  },
  {
    path: '/apply',
    name: 'apply',
    // redirect:'/home',
    component: () => import('../views/Apply.vue'),
    meta: {
      title: '申请使用'
    },

  },
  {
    path: '/produce',
    name: 'produce',
    redirect: '/produce/safesystem',
    component: () => import('../views/produce/home.vue'),
    children: [
      {

        path: 'safesystem',
        name: 'safesystem',
        // redirect:'/home',
        component: () => import('../views/produce/components/safeSystem.vue'),
        meta: {
          title: '安全生产双重预防控制系统'
        },

      },
      {

        path: 'group',
        name: 'group',
        // redirect:'/home',
        component: () => import('../views/produce/components/Group.vue'),
        meta: {
          title: '集团型企业安全生产信息化系统'
        },

      },
      {

        path: 'build',
        name: 'build',
        // redirect:'/home',
        component: () => import('../views/produce/components/build.vue'),
        meta: {
          title: '建筑施工企业安全生产管控平台'
        },

      },
      {

        path: 'industry',
        name: 'industry',
        // redirect:'/home',
        component: () => import('../views/produce/components/industry.vue'),
        meta: {
          title: '工业互联网+危化安全生产信息化系统'
        },

      },
      {

        path: 'special',
        name: 'special',
        // redirect:'/home',
        component: () => import('../views/produce/components/special.vue'),
        meta: {
          title: '特殊(危险)作业许可与作业过程管理系统'
        },

      },
      {

        path: 'big',
        name: 'big',
        // redirect:'/home',
        component: () => import('../views/produce/components/big.vue'),
        meta: {
          title: '大中型企业安全生产信息化系统'
        },

      },
    ]

  },
  {
    path: '/news',
    name: 'news',
    // redirect: '/news/safesystem',
    component: () => import('../views/news/home.vue'),
    children:[
      {
        path: 'one',
        name: 'one',
        // redirect: '/news/safesystem',
        component: () => import('../views/news/components/one.vue'),
        meta: {
          title: '新闻资讯'
        },
      },
      {
        path: 'two',
        name: 'two',
        // redirect: '/news/safesystem',
        component: () => import('../views/news/components/two.vue'),
        meta: {
          title: '新闻资讯'
        },
      },
      {
        path: 'three',
        name: 'three',
        // redirect: '/news/safesystem',
        component: () => import('../views/news/components/three.vue'),
        meta: {
          title: '新闻资讯'
        },
      },
      {
        path: 'four',
        name: 'four',
        // redirect: '/news/safesystem',
        component: () => import('../views/news/components/four.vue'),
        meta: {
          title: '新闻资讯'
        },
      },
      {
        path: 'five',
        name: 'five',
        // redirect: '/news/safesystem',
        component: () => import('../views/news/components/five.vue'),
        meta: {
          title: '新闻资讯'
        },
      },
      {
        path: 'six',
        name: 'six',
        // redirect: '/news/safesystem',
        component: () => import('../views/news/components/six.vue'),
        meta: {
          title: '新闻资讯'
        },
      },
    ]
  }




]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, form, next) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title + '|安云智数';
  }
  next()
});
export default router
