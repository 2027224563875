<template>
  <div>
    <!-- 底部 -->
    <el-row>
      <el-col :span="24">
        <div class="bg-purple-dark index-item" v-loading.fullscreen.lock="fullscreenLoading">
          <div class="footer-up" v-if="this.$route.path!='/apply'">
                <p style="font-size:32px ;font-weight:bold;">一键获取智能安全双控一体化解决方案</p>
                <p style="font-size:14px;">先进的产品、专业的解决方案帮您实现智能安全生产</p>
                <div class="input" v-if="isshow">
                <el-input v-model="info" placeholder="请输入您的手机号/邮箱，立即获取解决方案" style="height: 64px;" ></el-input>
                <button @click="submit">立即获取</button>
                </div>
                <div v-if="!isshow" style="font-size:28px;margin-top: 80px;">
                  提交成功!客服会在一个工作日内与您联系，请保持电话畅通!
                </div>
          </div>
          <div class="footer-down">
            <div class="up">
              <img src="../assets/images/index/footer-logo.png" alt=""  style="width:120px;height: 100px;"/>
              <div class="produce">
                 产品链接
                <div  class="item-content">  
                    <div>
                        <a href="#/produce/safesystem">
                            <p>安全生产双重预防控制系统</p>
                        </a>
                        <a href="#/produce/big">
                            <p>大中型企业安全生产信息化系统</p>
                        </a>
                        <a href="#/produce/group">
                            <p>集团型企业安全生产信息化系统</p>
                        </a>
                    
                  
                 
                    </div>
                    <div>
                        <a href="#/produce/industry">
                            <p>工业互联网+危化安全生产信息化系统</p>
                        </a><a href="#/produce/special">
                            <p>特殊(危险)作业许可与作业过程管理系统</p>
                        </a><a href="#/produce/build">
                            <p>建筑施工企业安全生产管控平台</p>
                        </a>
                    
                    
                    
                    </div>
                </div>
            </div>
              <div class="call">
                联系我们
                <div class="item-content">
                    <img src="../assets/images/index/footer-weixin.png" alt="">
                    <div style="width:330px">
                    <p><i class="el-icon-phone-outline"></i>18500047133 (郭先生)  </p>
                    <p><i class="el-icon-location-outline"></i>四川省成都市天府新区华阳街道华心中街122号</p>
                    <p><i class="el-icon-message"></i>vincent.guo@anyunzhishu.com</p>
                    </div>
                   
                </div>
            </div>
            </div>
            <div class="down">
              蜀ICP备2023020277号 | 川公网安备 51015602000643号 版权所有 四川安云智数科技有限公司 2023
              版权所有
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>


<script>
import {submitemail} from "@/api/index.js"
export default {
  name:'Footer-footer',
  data() {
    return {
      info:'',
      isshow:true,
      fullscreenLoading:false
    };
  },
  methods: {
    submit(){
      
      if(this.info){
        let obj={'tel':this.info}
        this.fullscreenLoading=true
        submitemail(obj).then(res=>{
          console.log(res,"res")
          if(res=='ok'){
            this.fullscreenLoading=false
            this.isshow=false
            this.$message.success("申请成功！")
          }
        })
        

      }else{
        this.$message.warning("请输入手机号或者邮箱！")
        this.isshow=true
      }
      
    }
  },
  created(){
    console.log(this.$route.path)
  }
};
</script>


<style scoped lang='scss'>
a{
    text-decoration: none;
    color: #222222;
}
a:hover{
    color: #005DD0;
    font-weight: bold;
}
.bg-purple-dark{
    height: 800px;
    width: 100%;
    font-family: PingFang SC;
    .item-title{
      width: 100%;
      text-align: center;
      padding-top: 20px;
    }
  }
  .footer-up{
    background-image: url('../assets/images/footer-bg.png');
    height: 300px;
    background-color: #005DD0;
    background-size:100% 100% ;
    padding:50px 0;
    color: #ffffff;
    // opacity: 0.5;
    // width: 70%;
    // margin: 0 auto;
    .input{
        display: flex;
        justify-content: flex-start;
        width: 30%;
        margin: 30px auto;
        
    }
    button{
        background-image: url('../assets/images/footer-button.png');
        border: 0;
        background-size:auto;
        background-position-y:-420px ;
        width: 150px;
        height: 50px;
        padding: 0;
    }
  }
  ::v-deep input{
    height: 50px;
  }
  .footer-down{
    width: 85%;
    margin: 0 auto;
    height: 300px;
    padding:50px 0;
    .up{
      width:100%;
        color: #222222;
        display: flex;
        justify-content: flex-start;

        .produce{
            font-size: 16px;
            // width: 550px;
            width:45%;
            text-align: left;
            margin-left: 5%;
        }
        .call{
            font-size: 16px;
            // width: 550px;
            text-align: left;
            margin-left: 5%;
        }
        .item-content{
        width:112%;
        //    color: #222222;
        margin-top: 20px;
           font-size: 14px;
            display: flex;
            justify-content: flex-start;
            div{
                width: 50%;
                // margin-right: 20px;
                p{
                    i{
                        font-size: 20px;
                        margin-right:10px ;
                    }
                }

            }
            img{
                    width: 100px;
                    height: 100px;
                    margin:10px 10px 10px 0;
                }
        }
    }
    .down{
        margin-top: 130px;
        font-size: 14px;
        color: #666666;
    }
  }
</style>